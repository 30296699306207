import { enableProdMode, LOCALE_ID, DEFAULT_CURRENCY_CODE, APP_INITIALIZER, ErrorHandler, Injector, importProvidersFrom } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from './environments/environment';
import { COMMIT_HASH } from './commithash';
import { AppComponent } from './app/app.component';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { StatuspalService } from '@gridscale/ingrid/helper/services/statuspal.service';
import { createErrorHandler } from '@sentry/angular';
import { GsHttpInterceptor } from './app/@shared/interceptors/gs-http.interceptor';
import { loginWithOneTimeToken, logoutSuccess } from './app/@shared/store/session/session.actions';
import { HttpClient, HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { Location, APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { debounceTime, filter, take } from 'rxjs/operators';
import { ActionsSubject, Store } from '@ngrx/store';
import { UiTranslateService } from '@gridscale/ingrid/helper/lang';
import { ErrorHandlerService } from './app/@shared/services/errorhandler.service';
import { SkinConfigService } from './app/@shared/services/skinconfig.service';
import { TranslateService, TranslateLoader, TranslateModule, MissingTranslationHandler } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from '@gridscale/moment-lite';
import { AppMissingTranslationHandler, DynamicCurrencyCode, DynamicLocaleId, TranslateCustomLoader, supportedLanguages } from './i18n';
import { TitleStrategy, provideRouter, withInMemoryScrolling } from '@angular/router';
import { TemplatePageTitleStrategy, appRoutes } from './app/app.routes';
import { selectUrl } from './app/@shared/store/router/router.selectors';

import { RootStoreModule } from './app/@shared/store/root-store.module';
import { setSamlFromConfig } from './app/@shared/store/user/user.actions';
import { saveConfig } from './app/@shared/store/config/config.actions';
import { ofType } from '@ngrx/effects';
import { ConfigService } from '@gridscale/ingrid/helper/services/config.service';
import { MajaConfigService } from '@gridscale/ingrid/helper/services/majaconfig.service';
import { UserStoreService } from './app/@shared/store/userstore/userstore.service';
import { SkinningService } from '@gridscale/ingrid/helper/services';




class ErrorCustomHandler implements ErrorHandler {
  private sentryErrorHandler = createErrorHandler({
    showDialog: false,
    logErrors: !environment.production
  });

  handleError(error: unknown) {
    if (environment.sentryLogErrors) {
      this.sentryErrorHandler.handleError(error);
    } else if (!environment.production) {
      console.error(error);
    }
  }
}


function initApp(
  translate: TranslateService,
  configService: ConfigService,
  majaConfigService: MajaConfigService,
  skinConfigService: SkinConfigService,
  skinningService: SkinningService,
  userStoreService: UserStoreService,
  errorHandler: ErrorHandlerService,
  uiTranslate: UiTranslateService,
  store: Store,
  location: Location,
  router: Router,
  actions$: ActionsSubject
): () => Promise<boolean> {
  return () =>
    new Promise<boolean>(async (resolve) => {
      errorHandler.init();
      skinningService.stylePropertyTargetElement$.next(document.documentElement); // this will automatically apply the css vars to the html element

      // Check if we are in an iFrame! Break out
      if (window.self !== window.top) {
        console.error('We are a Frame!! break out');
        _.set(window, 'top.location.href', '/');
      }

      // initialize the translate service
      translate.addLangs(supportedLanguages.map(_lang => _lang.code));
      translate.setDefaultLang('en');

      const paramLang = new URL(window.location.href).searchParams.get('lang');
      const currentBrowserLang = translate.getBrowserLang();
      const lang = document.createAttribute('lang');

      translate.onLangChange.subscribe(() => {
        lang.value = translate.currentLang;
        document.querySelector('html')?.attributes.setNamedItem(lang);

        try {
          uiTranslate.currentLang = translate.currentLang;
          moment.locale(translate.currentLang === 'de' ? 'de' : 'en-gb');
        } catch (e) {
          console.error('setting ingrid language', e);
        }
      });

      if (paramLang && supportedLanguages.findIndex(_lang => _lang.code === paramLang) !== -1) {
        translate.use(paramLang);
      } else if (supportedLanguages.findIndex(_lang => _lang.code === currentBrowserLang) !== -1) {
        translate.use(currentBrowserLang!);
      } else {
        translate.use(translate.defaultLang);
      }


      // wait until we have translations
      await translate.get('GENERAL.NAME').toPromise();

      // One Time Token login (magiclink)
      const urlParams = new URLSearchParams(document.location.search);
      if (urlParams.has('oneTimeToken')) {
        // do the login via one time token
        store.dispatch(loginWithOneTimeToken({ token: urlParams.get('oneTimeToken')! }));
        // Removes old oneTimeToken from the URL and prevents a error of an already used token, when doing a reload
        location.replaceState(window.location.pathname, '');
      }

      // Ticket validation
      const key = 'ticket-tokens';
      const ticketToken = new URL(window.location.href).searchParams.get('validateticket');

      if (ticketToken) {
        const existingTokens = localStorage.getItem(key);

        if (existingTokens !== null) {
          let alreadyExisting = false;
          existingTokens.split('::').forEach(token => {
            if (token === ticketToken) {
              alreadyExisting = true;
            }
          });

          if (alreadyExisting === false) {
            const newContent = existingTokens + '::' + ticketToken;
            localStorage.setItem(key, newContent);
          }
        } else {
          localStorage.setItem(key, ticketToken);
        }
      }

      try {
        await configService.loadApiConfig();
        try {
          await majaConfigService.loadApiConfig(environment.majaUrl)
        } catch (eMaja) {
          /* we don't care at the moment */
        }
        await skinConfigService.loadSkinConfig();

        store.dispatch(setSamlFromConfig({ data: configService.samls }));
        store.dispatch(saveConfig({ data: configService.configRaw }));

        actions$
          .pipe(ofType(logoutSuccess), debounceTime(100))
          .subscribe(() => {
            // After logout success our store will be cleared, so restore some data
            store.dispatch(setSamlFromConfig({ data: configService.samls }));
            store.dispatch(saveConfig({ data: configService.configRaw }));
          });

        store
          .select(selectUrl)
          .pipe(
            filter((url) => url !== undefined),
            take(1)
          )
          .subscribe((url) => {
            if (url.indexOf('/Access/error') === 0 || url.indexOf('/System/Error') === 0) {
              router.navigate(['/']);
            }
          });

        resolve(true);
      } catch (e) {
        resolve(true);
        router.navigate(['/System/Error']);
      }




    });
}



const myWindow: Window & { gs_version?: string; gs_version_hash?: string, gs_version_branch?: string } = window;
// the '<!--#echo....--> stuff is replace BY NGINX (module_ssi) during runtime!
myWindow.gs_version = '<!--#echo var="ssiPACKAGE_VERSION"-->';
myWindow.gs_version_hash = COMMIT_HASH;
myWindow.gs_version_branch = '<!--#echo var="ssiGIT_COMMIT_BRANCH"-->';

if (myWindow.gs_version.indexOf('$') === 0) {
  myWindow.gs_version = 'LOCALHOST';
}
if (myWindow.gs_version_hash.indexOf('$') === 0) {
  myWindow.gs_version_hash = 'LOCALHOST';
}
if (myWindow.gs_version_branch.indexOf('$') === 0) {
  myWindow.gs_version_branch = 'LOCALHOST';
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      RootStoreModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: TranslateCustomLoader,
          deps: [HttpClient, Location]
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useFactory: (injector: Injector) => {
            return new AppMissingTranslationHandler(injector);
          },
          deps: [Injector]
        }
      }),
    ),
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleId,
      deps: [TranslateService]
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useClass: DynamicCurrencyCode,
      deps: [Store]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [
        TranslateService,
        ConfigService,
        MajaConfigService,
        SkinConfigService,
        SkinningService,
        UserStoreService,
        ErrorHandlerService,
        UiTranslateService,
        Store,
        Location,
        Router,
        ActionsSubject
      ],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GsHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorCustomHandler
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    },
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy
    },
    provideRouter(
      appRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      })
    ),
    StatuspalService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: 'IS_CLOUD_PANEL',
      useValue: true
    }

  ]
})
  .catch(err => console.error(err));
