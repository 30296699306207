import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ErrorHandlerService } from '../../@shared/services/errorhandler.service';
import { getLastUrl, getNavigationCount } from '../../@shared/store/router/router.selectors';
import { withLatestFrom } from 'rxjs';
import { PageNotFoundComponent } from '@gridscale/ingrid/views/page-not-found';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: true,
  imports: [PageNotFoundComponent]
})
export class NotFoundComponent implements OnInit {
  readonly #store = inject(Store);
  readonly #errorHandlerService = inject(ErrorHandlerService);

  lastUrl?: string;

  ngOnInit(): void {

    this.#store.select(getLastUrl).pipe(
      withLatestFrom(this.#store.select(getNavigationCount))
    ).subscribe(([lastUrl, navCount]) => {
      if (navCount > 1) {
        this.lastUrl = lastUrl
      } else {
        this.lastUrl = undefined;
      }
    });

    this.#errorHandlerService.log({
      summary: 'Page not found',
      detail: window.location.href,
      displayToast: false,
      logToSentry: true
    });

  }
}
