import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { changeLanguageAction, noopAction, toastAction } from './@shared/store/common.actions';
import { debounceTime, distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';
import { DOCUMENT, Location, NgIf, NgClass } from '@angular/common';
import { setInitUrl } from './@shared/store/router/router.actions';
import { ConfigService } from '@gridscale/ingrid/helper/services/config.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { MessageService, PrimeNGConfig, SharedModule } from 'primeng/api';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { getCurrentUser } from './@shared/store/user/user.selectors';
import { HotkeysService } from './@shared/services/hotkeys.service';
import { AuthService } from './auth/auth.service';
import { environment } from './../environments/environment';
import { CopyClipboardModule } from '@gridscale/ingrid/components/copy-clipboard';
import { A11yStatusComponent } from '@gridscale/ingrid/components/a11ystatus';
import { ToastModule } from 'primeng/toast';
import { A11yLinkDirective } from '@gridscale/ingrid/helper/directives';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [MessageService],
    standalone: true,
  imports: [
    A11yLinkDirective,
    A11yStatusComponent,
    CopyClipboardModule,
    NgClass,
    NgIf,
    RouterOutlet,
    SharedModule,
    ToastModule,
    TranslateModule,
  ]
})
export class AppComponent implements OnInit {

  readonly #actions$ = inject(ActionsSubject);
  readonly #store = inject(Store);
  readonly #location = inject(Location);
  readonly #translate = inject(TranslateService);
  readonly #titleService = inject(Title);
  readonly #messageService = inject(MessageService);
  readonly #router = inject(Router);
  readonly #hkService = inject(HotkeysService);
  readonly #primeConfig = inject(PrimeNGConfig);
  readonly #authService = inject(AuthService);
  readonly #document = inject(DOCUMENT);
  readonly #renderer = inject(Renderer2);
  readonly #config = inject(ConfigService);

  protected appStable = false;

  openRequests: { fullAction: string, action: string, inserted: number }[] = [];


  ngOnInit() {
    if ((this.#config.isLocalhost ? environment.apiUrl : window.location.hostname).indexOf('gridscale') === -1) {
      this.#renderer.addClass(this.#document.body, 'brand');
    }



    this.#hkService.noop(); // just to have it initialized because of debug center

    // set the page title from translations
    this.#translate.stream('GENERAL.PAGE_TITLE', { name: this.#config?.partnerName }).subscribe(title => {
      this.#titleService.setTitle(title);
    });

    // show toast messages dispatched via ngrx actions
    this.#actions$.pipe(ofType(toastAction)).subscribe(message => {
      this.#messageService.add({
        key: 'global',
        ...message
      });
    });

    this.#store.dispatch(setInitUrl({ url: this.#location.path() }));

    // when current user is loaded, set the language
    this.#store
      .select(getCurrentUser)
      .pipe(filter(user => user?.language !== undefined))
      .subscribe(user => {
        this.#translate.use(user!.language!);
      });

    this.#actions$.pipe(
      ofType(changeLanguageAction),
      debounceTime(100)
    ).subscribe(action => {
      this.#translate.use(action.language);
    });

    let sessionValidReceived = false;
    let killFalsePositiveTimeout: number | undefined;

    this.#actions$.pipe(
      withLatestFrom(this.#authService.session$),
      map(([action, session]) => {
        const matchesLoading = action.type.match(/\[[a-zA-Z0-9_-]+\]\sLoad(.*)/i);
        const matchesLoadSelected = action.type.match(/\[[a-zA-Z0-9_-]+\]\sLoad Selected(.*)/i);
        const matchesSessionValid = action.type.match(/\[[a-zA-Z0-9_-]+\]\sSession Valid(.*)/i);
        const matchesNext = action.type.match(/\[[a-zA-Z0-9_-]+\]\sLoad (.*) Next/i);
        const matchesStart = action.type.match(/\[[a-zA-Z0-9_-]+\]\sLoad(.*) Start/i);
        const matchesSuccess = action.type.match(/\[[a-zA-Z0-9_-]+\]\sLoad(.*) Success/i);
        const matchesFailure = action.type.match(/\[[a-zA-Z0-9_-]+\]\sLoad(.*) Failure/i);

        const now = new Date();

        if (matchesSuccess) {
          const myReqs = _.filter(this.openRequests, { action: matchesSuccess[1] });

          _.forEach(myReqs, myReq => {
            this.openRequests = _.without(this.openRequests, myReq);
          });

        } else if (matchesFailure) {
          const myReq = _.find(this.openRequests, { action: matchesFailure[1] });
          if (myReq) {
            this.openRequests = _.without(this.openRequests, myReq);
          }
        } else if (matchesStart) {
          this.openRequests.push({ action: matchesStart[1], inserted: now.getTime(), fullAction: action.type });
        } else if (matchesLoadSelected || matchesNext) {
          // do nothing as we need the action with `Start` Suffix here and that would be handled in the prev. if
        } else if (matchesLoading) {
          this.openRequests.push({ action: matchesLoading[1], inserted: now.getTime(), fullAction: action.type });
        } else if (matchesSessionValid && !sessionValidReceived && session === null) {
          this.openRequests.push({ action: ' Session', inserted: now.getTime(), fullAction: action.type });
          sessionValidReceived = true;
        }

        // requests that are in the queue too long are considered false positives and removed
        this.openRequests = _.filter(this.openRequests, r => r.inserted > now.getTime() - 40000);

        if (this.openRequests.length > 0) {
          if (killFalsePositiveTimeout) {
            window.clearTimeout(killFalsePositiveTimeout);
          }
          killFalsePositiveTimeout = window.setTimeout(() => {
            killFalsePositiveTimeout = undefined;
            if (this.openRequests.length > 0) {
              // dispatch noop action to come into that handler again, and clear false positives
              this.#store.dispatch(noopAction());
            }
          }, 1000);
        }

        return this.openRequests.length === 0 && (sessionValidReceived || session !== null || this.#location.path().indexOf('/Access') === 0 || this.#location.path().indexOf('/Register') === 0);
      }),
      debounceTime(100),
      distinctUntilChanged(),
    ).subscribe(appStable => {
      this.appStable = appStable;
    });

    // override the route reuse strategy:
    // if we have route params which contain "_uuid" and they change between two routes, we do *not* reuse the route,
    // meaning that the component will be completely destroyed and recreated in this case. Otherwise it will lead to problems
    // e.g. when switching object via the nav-buttons, as we often use ngOnInit() to initialize our stuff
    this.#router.routeReuseStrategy.shouldReuseRoute = (curr, future) => {
      const curKeys = curr.params ? Object.keys(curr.params) : [];
      const uuidKeys = _.filter(curKeys, (o: string) => o.indexOf('_uuid') > 0);

      let uuidChanged = false;
      _.forEach(uuidKeys, key => {
        if (future.params[key] !== undefined && curr.params[key] !== future.params[key]) {
          // uuid changed
          uuidChanged = true;
        }
      });

      if (uuidChanged) {
        return false; // do not reuse
      }
      return future.routeConfig === curr.routeConfig;
    };

    this.#translate.get('PRIMENG').subscribe(res => this.#primeConfig.setTranslation(res));

    this.#translate.onLangChange.subscribe(() => this.#translate.get('PRIMENG').subscribe(res => this.#primeConfig.setTranslation(res)));

    // GD-15347: A11y: After routing we always focus the main header element to not confuse sightless people
    this.#router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      debounceTime(100)
    ).subscribe(() => {
      document.querySelector('header')?.focus();
    })
  }



}
